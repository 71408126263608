import React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const AdsSlot = dynamic(() => import('./AdsSlot'), {
    ssr: false
});

interface ContainerProps {
    $margin?: string;
}

const Container = styled.div<ContainerProps>`
    width: 600px;
    height: 250px;
    background: #f9f9f9;
    @media (max-width: 992px) {
        width: 300px;
        height: 100px;
    }
    ${({ $margin }: ContainerProps) => `margin: ${$margin ? $margin : 'auto'}`}
`;

interface Props {
    id: string;
    margin?: string;
}

const ExpandNavibox = ({ id, margin = 'auto' }: Props): JSX.Element => {
    if (!id) return null;
    return (
        <Container $margin={margin} key={id}>
            <AdsSlot slotId={id} />
        </Container>
    );
};

export default ExpandNavibox;
